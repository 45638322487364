export const actionTypes = {
  DELETE_UPLOADCARE_IMAGE: 'zola/cards/project/samplesFlow/DELETE_UPLOADCARE_IMAGE',
  RESET: 'zola/cards/project/samplesFlow/RESET',
  SET_ACCOUNT_DETAILS: 'zola/cards/project/samplesFlow/SET_ACCOUNT_DETAILS',
  SET_ALL_LP_OR_FOIL: 'zola/cards/project/samplesFlow/SET_ALL_LP_OR_FOIL',
  SET_CARD_SUITE_DETAILS: 'zola/cards/project/samplesFlow/SET_CARD_SUITE_DETAILS',
  SET_DEFAULT_TEMPLATES: 'zola/cards/project/samplesFlow/SET_DEFAULT_TEMPLATES',
  SET_DESIGN_CUSTOMIZATIONS: 'zola/cards/projects/samplesFlow/SET_DESIGN_CUSTOMIZATIONS',
  SET_ERRORS: 'zola/cards/project/samplesFlow/SET_ERRORS',
  SET_HAS_LP_OR_FOIL: 'zola/cards/project/samplesFlow/SET_HAS_LP_OR_FOIL',
  SET_ORDER_NUMBER: 'zola/cards/project/samplesFlow/SET_ORDER_NUMBER',
  SET_PAPER_CUSTOMIZATIONS: 'zola/cards/project/samplesFlow/SET_PAPER_CUSTOMIZATIONS',
  SET_SHIPPING_DETAILS: 'zola/cards/project/samplesFlow/SET_SHIPPING_DETAILS',
  SET_SHIPPING_DETAILS_FETCHED: 'zola/cards/project/samplesFlow/SET_SHIPPING_DETAILS_FETCHED',
  SET_SINGLE_SAMPLE_AVAILABLE: 'zola/cards/project/samplesFlow/SET_SINGLE_SAMPLE_AVAILABLE',
  SET_STEP_KEY: 'zola/cards/project/samplesFlow/SET_STEP_KEY',
  SET_UPLOADCARE_IMAGE: 'zola/cards/project/samplesFlow/SET_UPLOADCARE_IMAGE',
  SET_WEDDING_DETAILS: 'zola/cards/project/samplesFlow/SET_WEDDING_DETAILS',
  SET_PREVIEW_PHOTO: 'zola/cards/project/samplesFlow/SET_PREVIEW_PHOTO',
  UPDATE_DESIGN_CUSTOMIZATIONS: 'zola/cards/projects/samplesFlow/UPDATE_DESIGN_CUSTOMIZATIONS',
  UPDATE_TEMPLATE: 'zola/cards/project/samplesFlow/UPDATE_TEMPLATE',
};
